.profilePage {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.profilePageContent {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
}

.personalInfo {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 390px;
}

.wallet {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 260px;
}

.walletTitle {
  font-size: 1rem;
  margin-bottom: 10px;
}

.cardsContainer {
  max-height: 300px;
  overflow-y: auto;
}

.cardsContainer::-webkit-scrollbar {
  display: none;
}

.credit-card {
  background: #fff;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 8px;
  position: relative;
  width: 180px;
}

.credit-card-img {
  width: 35px;
  height: auto;
  margin-bottom: 10px;
}

.backBtn {
  margin-bottom: 20px;
}

.areaCode {
  flex: 1;
}

.PhoneNum {
  flex: 3;
}

.buttons {
  margin-top: 20px;
}

.wallet-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.walletButton {
  margin-top: 20px; /* 添加合适的上边距 */
  font-size: 1rem;
}

.deleteBtn {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: transparent;
  border: none;
  color: black;
  font-size: 1rem;
}
