.mainMenu {
  background: white;
  border: none;
}
.endItemUl {
  display: flex;
  flex-direction: row;
}
.endItemUl > li {
  margin: 20px;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
}

.endItemUl li:hover {
  background-color: lightgrey; /* Change background color on hover */
}
.endItemUl li:hover > a {
  color: white; /* Change background color on hover */
}
.activeItem {
  background-color: #06b6d4;
}
.activeItem > a {
  color: white !important;
}
.endItemUl > li > a {
  text-decoration: none;
  color: #06b6d4;
}
