.timeTab > ul {
  width: 100% !important
}
.timeTab > ul > li{
    width: 10%;
}

@media screen and (max-width: 850px) {
    .timeTab > ul > li{
        width: 25%;
    }
}