.quoteDialog {
  width: 400px;
  text-align: center;
}
.countryExchange {
  width: fit-content;
  margin: auto;
}
.inputLabelWrap > *{
    margin-left:20px
}
.cvcQuote  > label{
  margin-top: 10px;
  margin-left: -15px;
}
.cvcQuote > input{
  width: 80px;
  margin-left: 20px;
}
.cvcQuote{
  margin-left: 20px;
}
.exDate>  label{
  margin-top: 10px;
  margin-left: 5px;
}
.exDate> input{
  width: 80px;
  margin-left: -10px;
}
.address > input{
  margin-left: 8px;
}
.address > label{
  margin-left: -10px;
  margin-top: 10px;
}
.submitNextBtn{
  width: fit-content;
  margin: auto;
}
.err{
 color:red  
}