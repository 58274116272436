.orderPageWrap{
    margin: 4rem;
}
.buyingLab,.sellingLab{
    margin-top: 10px;
}
.border{
    border: 1px solid black;
}
.biIcon{
    color:black
}