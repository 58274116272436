.profileWrap > div {
  width: 75%;
  margin: auto;
}

.nameTag {
  margin-bottom: 2px;
}

.areaCode {
  width: 100px;
}

.PhoneNum {
  width: 175px;
}

.cardNumber {
  width: 200px;
}

.cameraBtn {
  font-size: 100px !important;
}

.backBtn {
  margin-left: 20px;
}

.profileWrap > * {
  margin-top: 300px;
}

.walletCurrency > * {
  width: 100%;
}

.label {
  font-size: 14px;
  margin-left: 6px;
  margin-bottom: 4px;
  display: block;
}

.cardRow {
  display: flex;
  justify-content: center;
}

.cardCols {
  flex: 1;
  min-width: 300px;
}

.creditCardCol {
  flex: 0.66;
}

.billingCol {
  flex: 1;
}

.submitButtons {
  margin-top: 20px;
}

.billingAddress {
  width: 350px;
  margin-bottom: 8px ;
}

.billingAddressContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.inputRow {
  display: flex;
  gap: 4px;
  margin-bottom: 10px;
  align-items: center;
}

.inputCol {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.fullWidth {
  width: 100%;
}

.flexContainer {
  display: flex;
}

.halfWidth {
  width: 50%;
}

.thirdWidth {
  width: 115px;
}

.inputContainer.fullWidth {
  width: 100%;
}

.phoneNumber {
  width: 234px;
}

.StripeElement {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 0px;
  font-size: 16px;
}
