.profileWrap > div {
  width: 75%;
  height: 150px;
  margin: auto;
  margin-left: 100px;
}
.nameTag {
  margin-bottom: 2px;
}
.transitNumber {
  width: 140px;
}
.branchNumber {
  width: 140px;
}
.accountNumber {
  width: 220px;
}
.bankName {
  width: 550px;
}
.cameraBtn {
  font-size: 100px !important;
}
.backBtn {
  margin-left: 20px;
}
.profileWrap > * {
  margin-top: 300px;
}
.walletCurrency > * {
  width: 100%;
}
.label {
  font-size: 14px;
  margin-left: 6px;
}
.cardRow {
  display: flex;
  justify-content: center;
}
.cardCols {
  margin-left: 80px;
  margin-right: 80px;
}