.inputWrap > span {
  margin: 10px;
}

.inputWrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
}
.inputWrap > * {
  margin-top: 15px;
}
.areaCodeField {
  width: fit-content;
  margin-left: 10px;
}
.areaCodeField :first-child {
  width: 70px !important;
}
.areaCodeField :nth-child(2) {
  width: 160px;
}
.nextStepBtn {
  float: right;
  margin-right: 30px;
}
.regDialog {
  max-width: 739px;
  width: 739px;
}

@media screen and (max-width: 739px) {
  .regDialog {
    width: 90%;
  }
}
.loginDialog {
  max-width: 339px;
  width: 339px;
}
.loginDialog > .p-dialog-content {
  text-align: center;
}

@media screen and (max-width: 739px) {
  .loginDialog {
    width: 90%;
  }
}

.stepBtnWrap {
  float: right;
}
.inputLoginWrap {
  display: block;
  margin: auto;
}
.inputLoginWrap > * {
  margin: auto;
  width: fit-content;
}
.swichBtnWrap {
  width: fit-content;
  margin: auto;
  text-align: center;
}
.swichBtnWrap > div {
  width: fit-content;
}
.swichBtnWrap > button {
  width: fit-content;
  margin-left: -2px;
  padding: 2px;
}
.logoDia {
  width: fit-content;
  margin: auto !important;
}
.err {
  color: red;
}
