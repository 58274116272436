.pageWrap{

    width: 100%;

}

@media screen and (max-width: 992px) {
    .pageWrap{
      flex-direction: column !important;
      gap:none !important;
    }
    .pageWrap > *{
        width: 100% !important;
    }
    .diverVertical{
        display: none
    }
  }
  
.pageWrap > *{
    width: 50%;
}
.idwrap ,.billWrap{
    width: fit-content;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;

}
.imgWrap > div {
    position: relative;
}
.imgWrap > div > button{
    position: absolute;
    right:0;
    top:0;
}
.imgWrap > div > img {
    width:100%;
    object-fit:scale-down;
}
.imgWrap{
    display: flex;

    flex-direction: row;
}
.iduploadBtn{
    width: 120px;
    margin: auto;
}
.billImg{
    width: 600px !important;
    height: 400px !important;
    
}
.submitBtn{
    width: 100px !important;
    float: right;
    position: fixed;
    right:20px;
    bottom: 20px;

}
.sellerWrap{
    margin-top: 40px;
    text-align: center;
}
.diverVertical{
    width: 10px;
}
.imgWrap > div{
    margin: auto;
}