.setupForexWrap {
  margin-top: 50px;
  width: 80%;
  margin-left: 10%;
}

.addBtnSetup {
  z-index: 1000;
  margin-bottom: 40px;
}

.refreshBtn {
  z-index: 1000;
  margin-bottom: 40px;
  margin-left: 10px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.wrapBuySellCurSetup > p {
  margin-top: 20px !important;
}

.wrapBuySellCurSetup > span {
  margin-top: 10px !important;
}

.rateAmount > input {
  width: 80px;
}

.wrapBuySellCurSetup {
  width: fit-content;
}

.setUpCurDia {
  text-align: center;
}

.maxValAllowed > label {
  margin-top: 10px !important;
}

.flagSetup {
  height: 20px;
}

.flagsExch > * {
  margin-left: 0px;
  margin-right: 20px;
}

.progress-bar-container {
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  height: 24px;
  position: relative;
}

.progress-bar {
  background-color: #007bff;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.progress-bar-text {
  font-size: 14px;
  line-height: 24px;
  color: black;
  font-weight: bold;
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 2;
}

.custom-table-container {
  max-height: 440px; /* Adjust this value to limit the number of visible rows */
  overflow-y: auto;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.custom-table-header,
.custom-table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr;
  gap: 10px;
  align-items: center;
  padding: 10px 0;
}

.custom-table-header {
  background-color: #f1f1f1;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 3;
  padding: 10px;
}

.custom-table-row {
  border-bottom: 1px solid #ddd;
  padding-left: 10px;
}
