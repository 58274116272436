.forexWrap {
  margin: auto;
  display: flex;
  flex-direction: row;
  width: fit-content;
}
.pageWrapProduct {
  width: 100%;
  height: fit-content;
}
.inputContainerExchange {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  grid-gap: 20px; /* Gap between grid items */
  max-width: 700px;

}
.amountInputAmtAndBtn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px; /* Gap between grid items */
  max-width: 700px;
  margin: auto;
}
.amountInputAmtAndBtn > div {
  margin-left: 0px;
}
@media screen and (max-width: 950px) {
  .inputContainerExchange {
    grid-template-columns: repeat(1, 1fr); /* Three equal columns */
  }
  .amountInputAmtAndBtn {
    grid-template-columns: repeat(1, 1fr);
  }
  .amountInputAmtAndBtn > div {
    margin: auto;
  }
}

.exchangeTxtContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Three equal columns */
  grid-gap: 20px; /* Gap between grid items */
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}
.item {
  background-color: white;
  width: fit-content;
  margin: auto;
}

.item > div {
  display: flex;
  flex-direction: row;

  margin: auto;
}
.item > div > * {
  margin-left: 20px;
}
.item > div > p {
  margin-top: 10px;
}
#swapIcon {
  margin: auto;
  font-size: 30px;
  cursor: pointer;
}
.flex {
  display: flex;
}
.selectorCurr {
  width: 200px;
}
.item {
  display: flex;
}
.item > * {
  margin-left: 20px;
}
.flagImg {
  width: 18px;
  margin-right: 10px;
}
.dollarWrap > h3 {
  margin-top: 5px !important;
}
#amountInput input {
  width: 200px !important;
}
#forexChart {
  margin: auto;
  height: 450px;
}

@media screen and (max-width: 900px) {
  #forexChart {
    width: 90%;
    margin-left: 5%;
  }
}
.item button {
  margin-left: 20px;
}
h4,
p {
  margin: 0;
  padding: 0;
}
.wrapCard {
  width: 70%;
  min-width: 380px;
  max-width: 800px;
  margin: auto;
  height: fit-content;
}
